<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title
        .description
          .input-holder
            om-select#shopifyDomain.mb-auto.w-100(
              v-model="shopifyDomain"
              :options="domains"
              :label="$t('integrationFlow.integrationModal.general.listHelper', { type: 'Shopify' })"
              :placeholder="$t('integrationFlow.integrationModal.selectAList')"
              optionText="customDomainAppearance"
            )
    .step
      .circle-holder
        .number 2
      integration-name(:name.sync="settings.name" :validations="validations")
</template>

<script>
  import { mapGetters } from 'vuex';
  import IntegrationName from '@/components/IntegrationModals/IntegrationName';

  export default {
    components: {
      IntegrationName,
    },
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        domains: [],
        shopifyDomain: null,
      };
    },

    computed: {
      ...mapGetters(['getActiveShopifyDomains']),
    },

    watch: {
      shopifyDomain({ key, value }) {
        const settings = this.settings;
        settings.shopifyDomain = value;
        settings.shopifyId = key;
        this.$emit('update:settings', { ...settings });
      },
    },

    async mounted() {
      // IMPORTANT to load data then set model (behavior of om-select)
      this.domains = [...this.getActiveShopifyDomains].map(
        ({ liveDomain, myShopifyDomain, shopifyId }) => {
          return {
            key: shopifyId,
            value: liveDomain,
            customDomainAppearance: `${liveDomain} (${myShopifyDomain})`,
          };
        },
      );

      const key = this.settings.shopifyId;
      const value = this.settings.shopifyDomain;

      // om-select selected option only needs "key" from object
      this.shopifyDomain = key
        ? { key, value }
        : { key: this.domains[0].key, value: this.domains[0].value };
    },
  };
</script>

<style lang="sass" scoped>
  #shopifyDomain
    font-size: 0.875rem
</style>
